import React from "react"

import socialImage from "../../static/DECAZES-social-share.jpg"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Genealogie from "../components/Genealogie"

const GenealogiePage = () => {
  return (
    <Layout>
      <SEO
        title="Généalogie"
        description="Découvrez l'abre généalogique simplifié de la famille Decazes"
        image={socialImage}
      />
      <Genealogie />
    </Layout>
  )
}

export default GenealogiePage
