import React from "react"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"
import { useSpring, animated } from "react-spring"

import "./styles.scss"
import three from "./ARBRE-GENEALOGIQUE.svg"
import threePDF from "../../../static/ARBRE-GENEALOGIQUE.pdf"

const Genealogie = () => {
  const textprops = useSpring({
    to: { opacity: 1, transform: "translateY(0)" },
    from: { opacity: 0, transform: "translateY(5%)" },
    config: { mass: 1, tension: 240, friction: 38, precision: 0.00001 },
    delay: 100,
  })
  return (
    <animated.div style={textprops} className="genealogie">
      <h1>Généalogie</h1>
      <h2>Arbre généalogique simplifié de la famille Decazes.</h2>
      <p className="genealogie-text-desktop">
        Scrollez pour zommer et maintenez le bouton de votre souris enfoncé pour
        vous déplacer.
      </p>
      <a href={threePDF} className="genealogie-text-mobile">
        Voir l'arbre généalogique
      </a>
      <div className="genealogie-three">
        <TransformWrapper>
          <TransformComponent>
            <img
              className="genealogie-three-image"
              src={three}
              alt="example-element"
            />
          </TransformComponent>
        </TransformWrapper>
      </div>
    </animated.div>
  )
}
export default Genealogie
